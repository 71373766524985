@import "../global";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
//html{scroll-behavior:smooth}
#app {
  background-color: $dark;
  overflow: hidden auto;

  .btn {
    cursor: pointer;
  }

  h1.display-2,
  h1.display-3 {
    hyphens: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
    @include media-breakpoint-down(md) {
      font-size: 36px;
    }
    @media screen and (max-width: 340px) {
      & {
        font-size: 30px;
      }
    }
  }

}

.steps {
  .step {
    padding-left: 40px;
    padding-top: 1.7px;

    margin-top: 1.5rem;
    position: relative;
    margin-bottom: 1.5rem;

    &:before {
      content: "1";
      @include gradient();
      border-radius: 50%;
      font-weight: 500;
      color: $light;
      position: absolute;
      width: 27px;
      height: 27px;
      padding-left: 9.5px;
      padding-top: 3.5px;
      left: 0;
      top: 0;
      font-size: 0.9rem;
    }

    &:nth-child(2):before {
      content: "2";
    }

    &:nth-child(3):before {
      content: "3";
    }

    &:nth-child(4):before {
      content: "4";
    }

    &:nth-child(5):before {
      content: "5";
    }

    &:nth-child(6):before {
      content: "6";
    }

    &:nth-child(7):before {
      content: "7";
    }

    &:nth-child(8):before {
      content: "8";
    }

    &:nth-child(9):before {
      content: "9";
    }

    &:nth-child(10):before {
      content: "10";
    }

    &:nth-child(11):before {
      content: "11";
    }

    &:nth-child(12):before {
      content: "12";
    }
  }


}

@import "header";
@import "footer";
@import "modules";
@import "../tiers";
@import "landing";
