.icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 2px;
  position: relative;
  min-width: 20px;

  &:after {
    display: block;
    content: '';
    padding-bottom: 100%;
  }

  @at-root .badge.badge-primary & {
    filter: brightness(100);
  }

  &.micassist {
    background-image: url("../../images/modules/micassist.svg");
  }

  &.streamassist {
    background-image: url("../../images/modules/streamassist.svg");
  }

  &.itemassist {
    background-image: url("../../images/modules/itemassist.svg");
  }

  &.extension {
    background-image: url("../../images/modules/extension.svg");
  }

  &.triggering {
    background-image: url("../../images/modules/triggering.svg");
  }

  &.npcoverassist {
    background-image: url("../../images/modules/npcoverassist.svg");
  }

  &.automationlink {
    background-image: url("../../images/modules/automationlink.svg");
  }

  &.scheduleassist {
    background-image: url("../../images/modules/scheduleassist.svg");
  }

  &.user {
    background-image: url("../../images/modules/user.svg");
  }

  &.npclipassist {
    background-image: url("../../images/modules/npclipassist.svg");
  }

  &.contentassist {
    background-image: url("../../images/modules/contentassist.svg");
  }

  &.upgrade {
    background-image: url("../../images/modules/add_modules.svg");
  }

  &.modules {
    background-image: url("../../images/modules/all_modules.svg");
  }
  &.notification_important{
    background-image: url("../../images/modules/notification_important.svg");
  }
  &.assignment{
    background-image: url("../../images/modules/assignment.svg");
  }
}


.module-list {
  margin-bottom: 1.5rem;

  .module {
    width: 100%;
    background: $light;
    display: flex;
    padding: 1.2rem 1rem;
    box-shadow: 0 1px 4px rgba(black, .08);
    margin-bottom: .8rem;
    border-radius: .2rem;
    transition: background .4s ease-in-out;
    position: relative;
    z-index: 0;
    cursor: pointer;
    align-items: center;


    &:after {
      z-index: -10;
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 0;
      transition: .3s ease-in-out;
      @include gradient();
    }

    &:hover {
      &:after {
        height: 3px;
      }
    }

    &.active {
      font-weight: $font-weight-bold;

      &:after {
        height: 3px;
      }
    }

    .icon {
      width: 24px;
      min-width: 24px;
    }

    p {
      margin-bottom: 0;
      padding-left: 1rem;
      hyphens: auto;
    }
  }
}
