.tiers {
  align-items: center;

  .tier {
    margin: 16px 0;
    color: $dark;
    padding: 35px;

    &:nth-child(1) {
      z-index: 10;
    }

    &:nth-child(3) {
      z-index: 10;
    }

    &:nth-child(2) {
      background: white;
      z-index: 100;

      &:after {
        @include gradient();
      }

      &:before {
        background: white;
      }

    }

    h1.pack {
      color: $gray-600;
      font-size: 2rem;
      //font-size: 1.5rem;
      display: flex;
      line-height: 100%;
      align-items: center;
      justify-content: space-between;

      span {
        color: white;
        font-size: $font-size-sm;
        @include gradient();
        padding: 5px;
        border-radius: 2px;
        line-height: 100%;
      }
    }

    h1.pack-price {
      margin: 1rem 0;
      line-height: 100%;
      display: flex;
      align-items: baseline;

      span {
        line-height: 100%;
        font-size: $font-size-sm;
      }
    }

    .overlay {
      position: absolute;
      top: -8px;
      left: -8px;
      width: calc(100% + 16px); // 5px border each side
      height: calc(100% + 16px); // 5px border each side
      display: flex;

      align-items: center;
      justify-content: center;
      background: rgba(black, .8);

      h3 {
        display: block;
        text-align: center;
        color: white;
      }
    }


    &:before {
      background: white;
      border-radius: 5px;
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

    }

    &:after {
      background: $light;
      z-index: -10;
      content: "";
      position: absolute;
      top: -8px;
      left: -8px;
      width: calc(100% + 16px); // 5px border each side
      height: calc(100% + 16px); // 5px border each side
      box-shadow: 0 1px 30px rgba(black, .5);
    }

    .module-list {
      .module {
        cursor: default;
      }
    }

    .btn:last-child {
      width: 100%;
    }

  }
}
