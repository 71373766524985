#app header {
  z-index: 1000;

  transition: background ease-in-out 0.3s;

  &.scrolled-down {
    nav {
      background: $gray-900;
    }

    &:after {
      opacity: 1;
    }

    nav.sub {
      transform: translateY(-100%);
      background: $gray-800;

      .items .item a {
        color: $light;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &.expanded {
      nav {
        background-color: $dark;
      }

      &:after {
        opacity: 1;
      }

      .navbar-brand:after {
        background: none !important;
      }
    }
  }

  nav {
    font-size: 0.95rem;
    padding: 0rem 0;
    transition: background ease-in-out 0.3s;


    .container {


      @include media-breakpoint-up(md) {
        .navbar-collapse {
          display: flex;
          justify-content: space-between;

          .col-sm-4 {
            padding: 0;
          }
        }
      }

      button.navbar-toggler {
        border: 0;
        padding: 0.75rem;
        outline: none;

        .navbar-toggler-icon {
          background: url("../../images/hamburger.svg") center no-repeat;
        }

        &[aria-expanded="true"] {
          .navbar-toggler-icon {
            background: url("../../images/close.svg") center no-repeat;
          }
        }
      }

      .navbar-brand {
        display: block;
        background-color: $gray-800;
        width: 280px;
        height: 60px;
        position: relative;
        box-shadow: 0 2px 15px rgba(black, 0.1);

        &:before {
          content: "";
          width: 100%;
          height: 100%;
          background: url("../../images/logo_main_text.svg") 50% 46% no-repeat;
          background-size: 89%;

          position: absolute;
          top: 0;
        }

        @include media-breakpoint-down(xs) {
          width: calc(100vw - 60px);
          margin-right: 0;
        }
      }

      a {
        color: white;
      }

      ul.navbar-nav {
        .nav-link {
          padding-left: 1rem;
          padding-right: 1rem;
          font-weight: $font-weight-bold;
        }

        &:hover {
          a.nav-link:not(:hover) {
            opacity: 0.5;
            transition: 0.25s ease-in-out;
          }
        }

      }
      .trial-email-block{
        margin-bottom: 0 !important;
      }
      .btn {
        font-size: 0.95rem;
      }

      .btn-secondary {
        margin-left: 0.5rem;
        background: rgba($gray-700, 0.5);
        border: none;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        transition: 0.4s ease-in-out;

        &:hover {
          background: $gray-700;
          //@include gradient();
        }
      }

      @include media-breakpoint-down(sm) {
        .col-sm-4 {
          padding-bottom: 1rem;

          .btn:first-child {
            padding-left: 0;
          }

        }
      }
    }
  }

  nav.sub {
    display: block;
    width: 100%;
    opacity: 0;
    position: absolute;

    box-shadow: 0 3px 20px rgba(black, 0.2);
    transition: 0.4s ease-in-out;
    z-index: -100;
    background-color: $light;

    &.active {
      opacity: 1;
      transform: translateY(0%);
    }

    .items {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      list-style: none;
      padding: 1rem 0;

      .item a {
        color: $gray-600;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75px;
        flex-direction: column;
        cursor: pointer;
        padding: 0 3rem;

        .icon {
          display: block;
          width: 45px;
          height: 45px;
          margin: 1.5rem 0.2rem;
        }

        h6 {
          margin-bottom: 0;
        }

        transition: 0.2s ease-in-out;

        &:hover {
          text-decoration: none;
          color: $primary;
        }
      }
    }
  }

  &:after,
  .navbar-brand:after {
    transition: opacity 0.3s ease-in-out;
    content: "";
    @include gradient();
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 3.5px;
  }


  &:after {
    opacity: 0;
  }
}
