/**
Dependencies
 */
// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,600,700,900');
// Variables
@import "variables";
// Bootstrap
@import '../../../node_modules/bootstrap/scss/bootstrap';


/**
VISUALRADIOASSIST CONTROL PANEL STYLE
=======================

SASS Functions
 */

@mixin z-depth-1 {
  box-shadow: 0px 7px 22px rgba(0, 0, 0, 0.06);
}

@mixin z-depth-2 {
  box-shadow: 0 1px 10px rgba(0, 0, 0, .16);
}

@mixin z-depth-3 {
  box-shadow: 0 2px 9px rgba(0, 0, 0, .07);
}

@mixin gradient($opacity: 1) {
  background-image: linear-gradient(45deg, rgba(241, 104, 43, $opacity) 0%, rgba(255, 90, 179, $opacity) 100%);
}

.grad {
  @include gradient();
}

/**
Nice uppercase styling
 */
//@for $i from 1 through 4 {
//  h#{$i}, .h#{$i}{
//    &:not([class*='display-']), &:not(.material-icons){
//      text-transform: uppercase;
//    }
//  }
//}

/*
 Bootstrap Corrections
  */

body {
  font-family: 'Roboto', sans-serif;
}

.text-muted {
  &:hover {
    color: $gray-600;
  }
}

// Buttons

.btn {
  font-size: $btn-font-size;
  transition: .3s ease-in-out !important;

  &.btn-primary {
    background-image: linear-gradient(45deg, #f1682b 0%, #ff5ab3 100%);
  }

  &.btn-primary:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .20);
  }

  &.btn-lg {

  }
}

// CARDS

.card {
  transition: box-shadow .2s ease-in, transform 100ms ease-in;
  box-shadow: 0 3px 12px rgba(black, 0.10);

  .card {
    box-shadow: 0 .5px 8px rgba(0, 0, 0, 0.08);
    background-color: $gray-700;
  }

  &.hover-effect {
    &:hover {
      box-shadow: 0 3px 15px rgba(black, 0.13);
    }

    &:active {
      transform: scale(.95);
    }
  }

  margin-bottom: 2rem;

  .card-footer {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      @include gradient();
    }
  }

  .card-header, .card-footer {
    //background: $dark;
  }

  .card-header {
    &h1, &h2 {
      margin-bottom: 0;
    }

    .badge {
      position: relative;
    }

    @at-root {
      h1#{&} .badge {
        top: -5px;
      }

      h2#{&} .badge {
        top: -3px;
      }
    }
  }

}

// MATERIAL ICONS

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  vertical-align: middle;
  display: inline-block;
  width: 1.05em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
  margin-bottom: 0.15em;
}

.z-depth-1 {
  @include z-depth-1();
}

.z-depth-2 {
  @include z-depth-2();
}

.z-depth-3 {
  @include z-depth-3();
}

.hover-effect {
  cursor: pointer;
  user-select: none;
  transition: .3s ease-in-out;
}


.badge {
  white-space: normal;
  text-align: left;
}

.modal {
  @include media-breakpoint-up("sm") {
    .modal-dialog {
      margin-top: 6rem;
    }
  }

  .modal-title {
    h2 {
      margin-bottom: 0;
    }
  }

  .modal-footer.gradient {
    &:after {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 3px;
      content: '';
      @include gradient();
    }
  }


}

.modal-backdrop {
  opacity: $modal-backdrop-opacity !important;
}

// VRA ICONS

i.material-icons {
  &.triggering::before {
    content: '\e838';
  }

  &.automationlink::before {
    content: '\e157';
  }

  &.micassist::before {
    content: '\e41f';
  }

  &.npcoverassist::before {
    content: '\e411';
  }

  &.npclipassist::before {
    content: '\e063';
  }

  &.extension::before {
    content: '\e87b';
  }

  &.itemassist::before {
    content: '\e866';
  }

  &.autoswitch::before {
    content: '\e01b';
  }

  &.streamassist::before {
    content: '\E8BF';
  }

  &.contentassist::before {
    content: '\e3ae';
  }

  &.scheduleassist::before {
    content: '\e878';
  }
}

.turbolinks-progress-bar {
  height: 3px;
  @include gradient();
  top: 57px;
  z-index: 1056;
}