// Colors
//$primary: #EB692C !default;
$primary: #fa607b !default;
// dark theme
$gray-900: #1e1f1e;
$gray-800: #2b2b2b;
$gray-700: #35393d;
$gray-600: #3f4950;

$gray-100: #f2f2f2;
$gray-200: #e9ecef;
$gray-300: #e6e6e6;
$gray-400: #a6a6a6;

//$nav-link-color
$nav-tabs-link-active-color: white;
// Body
//$body-bg:#f1682b;
$body-bg: black;
$body-color: $gray-200;

//$enable-shadows:true;

// Typography
$font-family-sans-serif: "Roboto", sans-serif;
$font-weight-base: 400;
$font-size-base: 1rem;
$text-muted: $gray-400;
$headings-font-weight: 600;
$font-weight-bold: 500;

a:not(.nav-link) {
  font-weight: 500;
}
//$headings-tran
//$line-height-base: 1.6;
//$text-color: #636b6f;

$display1-weight: 500;
$display2-weight: 500;
$display3-weight: 500;
$display4-weight: 500;

$display2-size: 4rem;
$display3-size: 3rem;
$display4-size: 2rem;

$border-radius: 0.15rem;
$border-radius-sm: 0.15rem;
$dropdown-border-radius: $border-radius;
// Navbar
$navbar-default-bg: #fff;
$nav-link-padding-x: 0.8rem;
//$navbar-

// Buttons
$btn-default-color: $body-color;
$btn-padding-y: 0.65rem;
$btn-padding-y-lg: 1.5rem;
$btn-padding-y-sm: 0.3rem;
$btn-padding-x: 1rem;
$btn-padding-x-lg: 1.5rem;
$btn-font-weight: 500;
$btn-font-size: 1rem;

$dropdown-item-padding-y: 0.5rem;

$list-group-bg: $gray-700;
$list-group-action-color: $gray-100;
$list-group-hover-bg: $gray-600;
$list-group-action-active-bg: $gray-800;
$list-group-active-bg: $gray-700;

// Card

$card-cap-bg: $gray-700;
$card-bg: $gray-800;
$card-spacer-y: 1.1rem;
$card-spacer-x: 1.3rem;
$card-border-width: 0px;
//$card-border-radius: 0.22rem;

// Inputs
$input-padding-y: 0.8rem;
$input-placeholder-color: $gray-400;
$input-color: $gray-200;
$input-bg: $gray-600;
$input-border-color: $gray-700;
$input-focus-border-color: $gray-600;
$input-group-addon-bg: $gray-700;
$input-group-addon-color: $gray-300;
$input-disabled-bg: black;
// modals
$modal-content-bg: $gray-800;
$modal-header-border-color: $gray-600;

$breadcrumb-bg: $gray-600;
$breadcrumb-divider-color: $gray-100;
$breadcrumb-active-color: $primary;

$nav-tabs-border-width: 0;

$modal-backdrop-opacity: 0.7;
$popover-header-color: $gray-900;
$popover-body-color: $gray-900;
$popover-body-padding-y: 1rem;
$popover-body-padding-x: 1rem;

//$popover-box-shadow: 0px 4px 10px rgba(black,.10);
//$popover-border-width:0;
$popover-font-size: $font-size-base;
