#app footer {
  margin-top: 3rem;
  position: relative;
  padding: 3rem 0;
  font-weight: 200;

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    height: 5px;
    width: 100%;
    left: 0;
    @include gradient();
  }

  .row {
    align-items: center;
    justify-content: center;

    .col-sm-6 {
      img {
        display: block;
        margin: 0 auto 1rem;
      }

      a {
        color: $light;
      }
    }
  }
}