/*
Sections on home
 */

#app {
  section {
    h1.title {
      margin-bottom: 3rem;
    }

    .trial-request {
      .trial-email-block {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;

        .btn {
          //white-space: nowrap;
          margin: 0;
        }
      }
    }


    &#hero {
      margin-bottom: 10rem;


      &:after {
        @include gradient();
        content: '';
        top: 0;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
        @include media-breakpoint-down(md) {
          clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10vw), 0% 100%);
        }
      }

      .container {
        position: relative;
        z-index: 100;
        padding-top: 25vh;
        min-height: 100vh;

        transform: translate(0, 0); //safari glitch free

        & > div {
          margin-left: auto;
          margin-right: auto;
          @include media-breakpoint-up(sm) {
            width: 75%;
          }
        }

        h1.display-3 {
          hyphens: none;
        }

        p {
          font-size: 1.2em;
          margin: 2.5rem 0;
        }

        .button-block {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          align-items: flex-start;
          justify-content: center;
        }

        .teaser {
          margin-left: auto;
          margin-right: auto;
          position: relative;
          display: block;
          margin-top: 4em;
          width: 80%;
          padding-bottom: 46%;
          z-index: 10000;
          height: 0;
          transform-style: preserve-3d;
          box-shadow: 0 -10px 40px rgba(black, .2);
          transform: translate(-50px, 0px) perspective(12.5cm) rotateX(6.3deg) rotateY(-7.1deg) rotateZ(6deg);
          @include media-breakpoint-down(md) {
            padding-bottom: 59%;
            width: 95%;
            transform: translate(-10px, 0px) perspective(12.5cm) rotateX(6.3deg) rotateY(-7.1deg) rotateZ(6deg);
          }


          &:before {
            content: '';
            left: 0;
            top: 0;
            //background: url("../../images/logo_main_text.svg") 50% 46% no-repeat;
            background: url("../../images/dashboard.png") center center no-repeat;
            background-size: 100% 100%;
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translateZ(0);
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: $light;
            opacity: .5;
            width: 100%;
            height: 100%;
            transform: scale(1.3) translateZ(-150px);
            transition: .3s ease-in;
            @include media-breakpoint-down(md) {

              transform: scale(1.1) translateZ(-56px);
            }
          }

          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    &#pipeline {
      margin: 5rem 0;

      #pipe-container {
        position: relative;
        height: 500px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        @include media-breakpoint-down(md) {
          height: auto;
          flex-direction: row;
          justify-content: space-around;
          #center-card {
            margin-top: 5rem;
            margin-bottom: 5rem;
          }
        }

        #center-card {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          .d-flex.flex-row {
            margin-top: 2rem;
            margin-bottom: 2rem;

            div {
              background: center center no-repeat;
              background-size: 80%;
              width: 50%;
              padding-bottom: 28%;
              padding-left: 1rem;
              padding-right: 1rem;
            }

            #obs {
              background-image: url("../../images/external-icons/OBS.svg");
              background-size: 40%;
              border-right: 1px solid rgba($light, .4);
            }

            #vmix {
              background-size: 65%;
              background-image: url("../../images/external-icons/vmix.svg");
            }
          }
        }

        .card {
          margin-bottom: 0;

          h4.icon {
            margin-left: auto;
            margin-right: auto;
            width: 50px;
          }

          .icon {
            background-size: 100%;
          }

          z-index: 100;

          h4 {
            display: flex;
            align-items: center;
          }


        }

        #right-cards {
          .card {
            .icon {
              margin-left: auto;
              margin-right: auto;
              width: 60%;

              &:after {
                padding-bottom: 50%;
              }
            }

            &#output-twitch .icon {
              background-image: url("../../images/external-icons/twitch.svg");
            }

            &#output-facebooklive .icon {
              background-image: url("../../images/external-icons/facebook_live.svg");
            }

            &#output-youtubelive .icon {
              background-image: url("../../images/external-icons/youtube_live.svg");
            }
          }
        }


        .fillers {
          height: 200px;
          width: 10px;
        }

        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;

          &.solid {
            line {
              stroke-dasharray: 1;
            }
          }

          line {
            stroke-width: 3px;
            stroke: $light;
            stroke-linecap: round;
            stroke-dasharray: 1 10;
          }
        }

        .line {
          width: calc(12.5% + 20px);
          left: calc(25% - 10px);
          position: absolute;
          border-top: 5px dotted $light;
        }
      }
    }

    &.module {
      position: relative;
      color: $dark;

      &:not(#automationlink) {
        padding-top: 10%;
        @include media-breakpoint-down(md) {
          padding-top: 10vw;
        }
      }

      padding-bottom: 3rem;
      margin-bottom: -10%;
      @include media-breakpoint-down(md) {
        margin-bottom: -10vw;
      }
      z-index: 10;

      .container {
        position: relative;
        z-index: 10;
      }

      .row {
        align-items: center;

        .col-sm-4 {
          h2 {
            margin-bottom: 1rem;
          }

          .icon {
            width: 55px;
            margin-bottom: 1rem;
          }
        }

        .col-sm-8 {
          padding: 12rem 5rem;
          @include media-breakpoint-down(md) {
            padding: 1rem;
          }

          .image {
            width: 100%;
            @at-root section.module:not(#contentassist) .image {
              box-shadow: 0 5px 30px rgba(0, 0, 0, .2);
            }
            @at-root section.module#contentassist .col-sm-8 {
              padding: 12rem 1rem;
            }
          }

          //.image {
          //  margin-left: auto;
          //  min-height: 50vh;
          //  background-position: center center;
          //  background-size: 100% auto;
          //  background-repeat: no-repeat;
          //  background-color: transparent;
          //  @at-root section.module#automationlink .image {
          //  background-size: auto 100%;
          //    background-image: url("../../images/modules/images/automationlink.png");
          //  }
          //  @at-root section.module#contentassist .image {
          //    background-image: url("../../images/modules/images/contentassist.png");
          //  }
          //  @at-root section.module#scheduleassist .image {
          //    background-image: url("../../images/modules/images/scheduleassist.png");
          //  }
          //}

        }

      }

      &:nth-child(odd) {
        .col-sm-8 {
          order: 0;

          .card {
            margin-left: inherit;
            margin-right: auto;
          }
        }

        .col-sm-4 {
          order: 1;
          text-align: right;

          .trial-email-block {
            justify-content: flex-end;
          }

          .icon {
            margin-left: auto;
          }
        }
      }

      &:before {
        //background
        //@include gradient();
        background-color: $light;
        //background-color: $gray-100;
        content: '';
        top: 0;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
        @include media-breakpoint-down(md) {
          clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10vw), 0% 100%);

        }
      }

      &#scheduleassist {
        z-index: 9;

        &:before {
          background-color: $gray-100;
        }
      }

      &#contentassist {
        z-index: 8;

        &:before {
          background-color: $white;
        }
      }

      &#automationlink {
        &:before {
          background-color: white;
        }
      }
    }

    &#features {
      padding-top: 10%;
      @include media-breakpoint-down(md) {
        //padding-top: 10vw;
      }
      background-color: $light;

      .col-sm-4.module-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;

        .module:last-child {
          //margin-bottom: 0;
        }
      }

      .col-sm-8 {
        padding: 1rem;
        padding-bottom: 50%;
        position: relative;

        .card {
          position: absolute;
          top: 50%;
          left: 0;
          opacity: 0;
          width: 100%;
          transition: .3s ease-in-out;
          background-color: transparent;
          margin-bottom: 0;
          transform: translateY(-50%);
          margin-left: auto;
          box-shadow: 0 -4px 20px rgba(black, .2);


          &:not([data-modulename='npcoverassist']) {
            //box-shadow: none;
          }

          .image {
            width: 100%;
            object-fit: cover;
          }

          .overlay-block {
            background: $light;
            color: $dark;
            padding: 1rem;
            position: absolute;
            right: 2.5rem;
            bottom: 2.5rem;
            transform: translateX(60%);
            opacity: 0;
            width: 70%;
            border-radius: 2px;
            overflow: hidden;
            box-shadow: 0 -4px 20px rgba(black, .2);

            transition: .5s ease-in;

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 4px;
              @include gradient();

            }
          }


          &[data-modulename='contentassist'], &[data-modulename='scheduleassist'], &[data-modulename='triggering'] {
            box-shadow: none;
          }

          &[data-modulename='triggering'] {

            .overlay-block {
              top: 8rem;
              bottom: inherit;
              transform: translateX(60%);
            }
          }

          &.active {
            opacity: 1;
            display: block;

            .overlay-block {
              opacity: 1;
              transform: translateX(0);

            }
          }
        }

        @include media-breakpoint-down(md) {
          & {
            padding-bottom: 0;

            .card {
              position: relative;
              display: none;
              top: 0;
              transform: none;

              .overlay-block {
                position: relative;
                width: 100%;
                bottom: 0;
                left: 0;
                right: 0;
              }
            }
          }
        }
      }
    }

    &#tryout {
      .row {
        overflow: hidden;
        border-radius: .3rem;
        @include gradient();

        .col-sm-7 {
          padding: 4rem;
          padding-right: 9rem;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          text-align: left;

          .btn {
            margin-bottom: 1rem;
          }
        }

        .col-sm-5 {
          padding-bottom: 35%;

          .img-holder {
            width: 100%;
            @include media-breakpoint-up(sm){
              position: absolute;
              left: -5rem;
              top: 50%;
              transform: translateY(-50%);
            }
            box-shadow: -5px 2px 20px rgba(black, .3);

            .video-overlay {
              iframe {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
              }

              #play {
                opacity: 0;
                padding: 0;
                font-size: 56px;
                transform: translate(-50%, -50%);
                margin-left: 0;
                width: auto;
                height: auto;

                &:hover {
                  transform: translate(-50%, -50%) scale(.9);
                }
              }
            }

            img {
              width: 100%;
            }
          }
        }

        @include media-breakpoint-down(md) {
          .col-sm-7 {
            padding: 3rem;
          }
          .col-sm-5 {
            padding-bottom: 0;
            padding: 0;

            img {
              position: relative;
              width: 100%;
              top: 0;
              left: 0;
              transform: none;
            }
          }
        }
      }

      &:hover {
        //tryout hover
        #play {
          opacity: 1 !important;
        }
      }
    }

    &#pricing {

    }

  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transform-style: preserve-3d;

    .player-wrapper {
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: absolute;

      iframe {
        position: absolute;
        top: -15%;
        left: -5%;
        width: 110%;
        height: 130%;
        z-index: 10;

      }

      .player-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 50;
      }
    }

    #play {
      color: $primary;
      position: absolute;
      width: 160px;
      height: 160px;
      margin-left: 20px;
      top: calc(50%);
      left: calc(50%);
      font-size: 72px;
      transform: translateX(-50%) translateY(-50%) translateZ(30px);
      padding: 30px;
      cursor: pointer;
      transition: .3s ease-in-out;
      text-shadow: 0 2px 30px rgba(0, 0, 0, 0.9);
      z-index: 100;
      @include media-breakpoint-down(md) {
        width: 110px;
        margin-left: 10px;
        height: 110px;
        font-size: 52px;
      }

      &:hover {
        transform: translateX(-50%) translateY(-50%) translateZ(5px);
      }
    }
  }
}
